
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SampleColors',
  data: () => ({
    colors: ['grey', 'primary', 'secondary', 'error', 'success', 'warning'],
  }),
  methods: {
    setCssVariables(color: string, weight: number): string {
      return '--samplecolors-bg-color: var(--color-' + color + '-' + weight + '00)';
    },
  },
});
